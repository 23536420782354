import React from 'react'
import { Link } from "gatsby"
import Head from '../components-en/head'
import Layout from '../components-en/layout'
import '../styles/grid.scss'
import '../styles/index.scss'

const Rules = () => {
    return (
        <Layout>
            <Head title="Çember Kuralları" description="Çember yakınlarınla bir araya gelerek altın günü modeli ile birikim yapabildiğin bir uygulamadır. Çember oluşturma kurallarını bu sayfada bulabilirsin."  />
            <div className="row rules">
       <div className="hero-container">
        <div className="container">
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="hero-text">
                <h1>Çember Oluşturma Kuralları <span role="img" aria-label="emoji">✍️</span></h1>
                <p>
                Çember, yakınlarınla bir araya gelerek <b>altın günü modeli</b> ile birikim yapabildiğin bir uygulamadır. 
                </p>
                <h3>Çember ile Birikim için Bilmen Gerekenler</h3>
                <div>
                  <ul>
                    <li>
                    Birikim yapmak istediğin tutarı hedefe koyar, <b>aylık birikim yapabileceğin miktarı</b> ve kişi sayısını belirleyerek bir çember oluşturursun. 
                    </li>
                    <li>
                    Oluşturduğun çembere <b>arkadaşlarını</b> çağırarak hedeflediğin kişi sayısını doldurursun. 
                    </li>
                    <li>
                    Çember oluştururken, sıra sana geldiğinde <b>aylık birikim tutarının yatırılmasını</b> istediğin hesabını çembere tanımlarsın.
                    </li>
                    <li>
                    Hedeflenen <b>kişi sayısına</b> ulaştığınızda çember başlar. 
                    </li>
                    <li>
                    Uygulama ödeme alma sırasını <b>kura</b> ile belirler. Çember başladığı çemberdeki her katılımcıya bildirim gider. Çember detay ekranında katılımcıların hangi ay ödeme alacağı listelenir.
                    </li>
                    <li>
                    Her ay, çemberde belirtilen aylık tutarı o ay ödeme alma sırası olan arkadaşına gönderirsin.
                    </li>
                    <li>
                    Ödeme yapma günü geldiğinde, uygulama çemberdeki herkese hatırlatma gönderir.
                    </li>
                    <li>
                    Çemberdeki herkes ödeme alana kadar çember devam eder. Ödemesini alan Çemberlerim - Alacağın Ödemeler sekmesinden aldığı ödemeyi onaylar. 
                    </li>
                    <li>
                    Çemberde sırası henüz gelmemiş olanlar yeşil, sırası geçmiş olanlar gri görünür.
                    </li>
                    <li>
                    Ödemeler onaylanana kadar o kişinin sırası geçmemiş görünür.
                    </li>
                  </ul>
                </div>

                <h3>Unutma!</h3>
                <p>
                Çember App kullanıcılarından <b>herhangi bir ödeme almaz, hesabından para çekmez. </b> 
                Çember oluştururken ve çembere katılırken bir banka hesabı seçmenin sebebi aylık birikim 
                tutarının çember katılımcılarının hesaplarına gönderilmesidir. Çember katılımcılarının 
                <b> IBAN numaranı kolayca kopyalayarak para gönderebilmesi</b> için hesabını eklersin. 
                Uygulamada hesabınla ilgili <b>başka hiçbir işlem</b> yapılmaz.

                </p>
               
              </div>
            </div>
            <div className="col-2"></div>
          </div>

          <div className="row faq">
          <div className="container">
            <div className="row section-title">
              <h1>Sık Sorulan Sorular <span role="img" aria-label="Emoji"> 🤔 </span> </h1>
            </div>
            <div className="row">
                <ul>
                  <li>
                    <span>Çemberde Nasıl Birikim Yaparsın?</span>
                    <p>
                    Çemberdeki her katılımcı <b>sırayla birbirine</b> borç verir, bu sayede herkesin her ay küçük küçük 
                    birikim yapması ve <b>tek seferde toplu bir paranın</b> eline geçmesi sağlanmış olur.
                    Çember ile biriktirmenin kendi kendine birikim yapmaktan farkı, <b> arkadaşlarınla birlikte motive olabilmeni sağlaması</b>
                    ve arkadaşlarına söz verdiğin için vazgeçmenin daha zor olmasıdır. Kaç kere kendi kendine birikim yapmaya karar verip aylık biriktirmeyi hedeflediğin miktarı başka bir yere harcadın hatırla.
                    </p>
                  </li>
                  <li>
                    <span>Çemberde Kimlerle Birikim Yapabilirsin?</span>
                    <p>
                    Birikim yapacağın çemberi yakın çevrenle (ailen, yakın arkadaşların, iş/okul arkadaşların) organize etmen gerekiyor. 
                    </p>
                  </li>                
                  <li>
                    <span>Çember App ile Birikim Yaparken Dikkat Etmen Gerekenler</span>
                    <p>
                    Katıldığın çemberde her ödeme yaptığında, ödeme yaptığın kişiye borç veriyorsun. Senin ödeme alma sıran geldiğinde de o kişiden verdiğin parayı geri alıyorsun. Ödeme sırası gelen, o ana kadar borç verdiği kişilerden borcunu geri almış, o andan sonra ödeme yapacağı kişilerden de borç almış oluyor. Bu sayede çemberdeki katılımcılar olarak birbirinizi finanse etmiş oluyorsunuz. 
                    </p>
                  </li>
                  <li>
                    <span>Birlikte Çembere Katıldığım Birisi Ödeme Yapmazsa Ne Olacak?</span>
                    <p>
                    Her kullanıcı çembere katılmakla, bir çember başladığında ve ödeme yapıldığında çemberdeki 
                    diğer katılımcılarla bir borç ilişkisine girdiğini kabul eder. (Bu kurallar 
                    <Link to="/user-agreement"> kullanıcı sözleşmesinde</Link> de belirtiliyor.) 
                    Yakın çevrenle birikim yapacağın için bir problem yaşamamanı temenni ediyoruz. 
                    Bir problem yaşaman durumunda ise yasal yollara başvurduğunda ödeme alamadığın kişilerin tüm 
                    bilgilerini ve uygulamadaki hareketlerini yetkili mercilerle paylaşacağımızı taahhüt ediyoruz. 
                    Yine de mağdur olmaman için <b>gerçekten güvendiğin kişilerle</b> birikim yapman gerektiğini hatırlatalım.
                    </p>
                  </li>
                  <li>
                    <span>Yabancılarla Birikim Yapabilir miyim?</span>
                    <p>
                    Şu an için hayır. Bunu gerçekleştirebilmek için çalışıyoruz. İlgi alanlarına ve birikim hedeflerine göre birbirini tanımayan kullanıcılarımızı bir araya getirebilmek için banka ve diğer ilgili kuruluşlarla çalışmalarımız devam ediyor.
                    </p>
                  </li>                                
                </ul>
            </div>
          </div>
      </div>
        </div>  
      </div>
      </div>
        </Layout>
    )
}

export default Rules